import React, {useState} from "react";

import moment from "moment";

import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

import {KeyboardDatePicker} from "@material-ui/pickers";

import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        "&:first-child": {
            marginLeft: 0,
        },
    },
    button: {
        height: "56px",
        margin: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
const CanteenReportsFilter = ({canteens, companies, query, setQuery, handleRequest}) => {
    const classes = useStyles();
    const [errors, setErrors] = useState({
        canteenId: "",
        companyId: "",
        startDate: "",
        endDate: "",
    });
    const [inputValue, setInputValue] = React.useState("");

    const companyOptions = [0, "", ...companies.map((company) => company.id)];

    const handleChange = (event) => {
        setQuery((query) => {
            const updatedQuery = {...query};
            updatedQuery[event.target.name] = event.target.value;
            return updatedQuery;
        });
    };
    const handleSubmit = () => {
        setErrors((errors) => ({canteenId: "", companyId: "", startDate: "", endDate: ""}));
        let errors = false;
        if (!query.canteenId) {
            setErrors((errors) => ({...errors, canteenId: "Required"}));
            errors = true;
        }
        if (query.companyId === null) {
            setErrors((errors) => ({...errors, companyId: "Required"}));
            errors = true;
        }
        if (!moment(query.startDate).isValid()) {
            setErrors((errors) => ({...errors, startDate: "Invalid Date"}));
            errors = true;
        }
        if (!moment(query.endDate).isValid()) {
            setErrors((errors) => ({...errors, endDate: "Invalid Date"}));
            errors = true;
        }
        if (errors) return;
        handleRequest();
    };
    return (
        <div className={classes.container}>
            <FormControl variant="outlined" className={classes.formControl} error={!!errors.canteenId}>
                <InputLabel id="canteen-label">Canteen</InputLabel>
                <Select
                    labelId="canteen-label"
                    id="canteen-select"
                    label="Canteen"
                    value={query.canteenId}
                    onChange={handleChange}
                    name="canteenId"
                >
                    {canteens.map((canteen) => (
                        <MenuItem key={`canteen-id-${canteen.id}`} value={canteen.id}>
                            {canteen.location.name}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{errors.canteenId}</FormHelperText>
            </FormControl>
            <Autocomplete
                value={query.companyId}
                id="company-select"
                options={companyOptions}
                style={{width: 300}}
                getOptionLabel={(option) => {
                    if (option === "") {
                        return "Guests";
                    } else if (option === 0) {
                        return "All";
                    } else {
                        const company = companies.find((company) => company.id === option);
                        return company.name;
                    }
                }}
                onChange={(event, newValue) => {
                    setQuery((query) => ({...query, companyId: newValue}));
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Company"
                        variant="outlined"
                        error={!!errors.companyId}
                        helperText={errors.companyId}
                        className={classes.formControl}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                        }}
                    />
                )}
            />
            <KeyboardDatePicker
                className={classes.formControl}
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Start Date"
                format="DD/MM/yyyy"
                value={query.startDate}
                InputAdornmentProps={{position: "start"}}
                onChange={(date, value) => {
                    if (moment(date).isValid()) {
                        const strDate = date.format("DD/MM/YYYY");
                        const utcDate = moment.utc(strDate, "DD/MM/YYYY");
                        setQuery((query) => ({...query, startDate: utcDate.toDate()}));
                    } else {
                        const strDate = moment(date).format("DD/MM/YYYY");
                        const utcDate = moment.utc(strDate, "DD/MM/YYYY");
                        setQuery((query) => ({...query, startDate: utcDate.toDate()}));
                    }
                }}
                name="startDate"
                error={!!errors.startDate}
                helperText={errors.startDate}
            />
            <KeyboardDatePicker
                className={classes.formControl}
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="End Date"
                format="DD/MM/yyyy"
                value={query.endDate}
                InputAdornmentProps={{position: "start"}}
                onChange={(date, value) => {
                    if (moment(date).isValid()) {
                        const strDate = date.format("DD/MM/YYYY");
                        const utcDate = moment.utc(strDate, "DD/MM/YYYY");
                        setQuery((query) => ({...query, endDate: utcDate.toDate()}));
                    } else {
                        const strDate = moment(date).format("DD/MM/YYYY");
                        const utcDate = moment.utc(strDate, "DD/MM/YYYY");
                        setQuery((query) => ({...query, endDate: utcDate.toDate()}));
                    }
                }}
                error={!!errors.endDate}
                helperText={errors.endDate}
                name="endDate"
            />
            <Button
                onClick={handleSubmit}
                className={classes.button}
                variant="contained"
                disableElevation
                size="large"
                color="primary"
            >
                Request Data
            </Button>
        </div>
    );
};
export default CanteenReportsFilter;
