export const ALL_RECEPTIONS_REQUEST = "ALL_RECEPTIONS_REQUEST";
export const ALL_RECEPTIONS_REQUEST_SUCCESS = "ALL_RECEPTIONS_REQUEST_SUCCESS";
export const ALL_RECEPTIONS_REQUEST_FAILURE = "ALL_RECEPTIONS_REQUEST_FAILURE";

export const ADD_RECEPTION = "ADD_RECEPTION";
export const ADD_RECEPTION_SUCCESS = "ADD_RECEPTION_SUCCESS";

export const UPDATE_RECEPTION = "UPDATE_RECEPTION";
export const UPDATE_RECEPTION_SUCCESS = "UPDATE_RECEPTION_SUCCESS";

export const DELETE_RECEPTION = "DELETE_RECEPTION";
export const DELETE_RECEPTION_SUCCESS = "DELETE_RECEPTION_SUCCESS";
