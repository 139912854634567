import {
  LOGIN_REQUESTING,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SUBMIT_FORGOT_PASSWORD_FAIL,
  SUBMIT_FORGOT_PASSWORD_SUCCESS,
  SUBMIT_FORGOT_PASSWORD_REQUESTING,
  VALIDATE_RESET_TOKEN_REQUESTING,
  VALIDATE_RESET_TOKEN_FAIL,
  VALIDATE_RESET_TOKEN_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_REQUESTING,
  RESET_PASSWORD_SUCCESS,
} from "../constants/auth";

const initialState = {
  requesting: false,
  successful: false,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUESTING:
    case VALIDATE_RESET_TOKEN_REQUESTING:
    case SUBMIT_FORGOT_PASSWORD_REQUESTING:
    case RESET_PASSWORD_REQUESTING:
      return {
        requesting: true,
        successful: false,
        error: "",
      };
    case LOGIN_SUCCESS:
    case SUBMIT_FORGOT_PASSWORD_SUCCESS:
    case VALIDATE_RESET_TOKEN_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      return {
        requesting: false,
        successful: true,
        error: "",
      };
    case LOGIN_FAILURE:
    case SUBMIT_FORGOT_PASSWORD_FAIL:
    case VALIDATE_RESET_TOKEN_FAIL:
    case RESET_PASSWORD_FAIL:
      return {
        error: action.error,
        requesting: false,
        successful: false,
      };
    default:
      return state;
  }
};

export default reducer;
