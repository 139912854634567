import React, { useEffect, useState } from "react";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

import { ALL_CANTEENS_REQUEST } from "../redux/constants/canteen";
import { ALL_COMPANIES_REQUEST } from "../redux/constants/company";
import { queryCanteenReport, cleanupReport } from "../redux/actions/canteen";
import PageTitle from "../components/PageTitle";
import Loader from "../components/Loader";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import CanteenReportsFilter from "../containers/CanteenReportsFilter";

const useStyles = makeStyles((theme) => ({
  rightAlignHeader: {
    "& span": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
  },
}));

const ManageCanteensPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const canteens = useSelector((state) => state.canteen.canteens);
  const report = useSelector((state) => state.canteen.canteenReportOrders);
  const orders = report.orders;
  const companies = useSelector((state) => state.companies.companies);
  const requesting = useSelector((state) => state.request.requesting);

  const data = Object.keys(orders).map((key) => orders[key]);
  const [query, setQuery] = useState({
    canteenId: "",
    companyId: 0,
    startDate: moment.utc().startOf("month"),
    endDate: moment.utc().endOf("month"),
  });
  useEffect(() => {
    dispatch({ type: ALL_CANTEENS_REQUEST });
    dispatch({ type: ALL_COMPANIES_REQUEST });
    return () => {
      dispatch(cleanupReport());
    };
  }, [dispatch]);

  const handleQueryRequest = () => {
    dispatch(queryCanteenReport(query));
  };

  const columns = [
    {
      name: "orderNumber",
      label: "Order Number",
      options: {},
    },
    {
      name: "orderQuantity",
      label: "Quantity",
      options: {
        setCellHeaderProps: () => ({
          className: classes.rightAlignHeader,
        }),
        setCellProps: () => ({
          align: "right",
        }),
      },
    },
    {
      name: "orderTotal",
      label: "Total",
      options: {
        setCellHeaderProps: () => ({
          className: classes.rightAlignHeader,
        }),
        setCellProps: () => ({
          align: "right",
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return value + " kr.";
        },
      },
    },
  ];

  const options = {
    onDownload: (_) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const fileName = `canteen-${
        query.canteenId
          ? canteens.find((canteen) => canteen.id === query.canteenId).location.name
          : "undefined"
      }-${moment(query.startDate).format("DD_MM_yyyy")}-${moment(query.endDate).format(
        "DD_MM_yyyy"
      )}`;
      var ws1Data = [];
      var ws2Data = [];
      var orderRows = [];
      var i = 2;
      data.forEach((order, index) => {
        let [_companyRow] = ws1Data.filter((item) => item.Company === order.companyName);
        if (_companyRow) {
          _companyRow.OrderAmount = _companyRow.OrderAmount + 1;
          _companyRow.Total = _companyRow.Total + Number(order.orderTotal);
          _companyRow.Tax = _companyRow.Tax + Number(order.orderTotal) * 0.25;
          _companyRow.TotalWithTax = _companyRow.TotalWithTax + Number(order.orderTotal) * 1.25;
        } else {
          ws1Data.push({
            Company: order.companyName,
            OrderAmount: 1,
            Total: Number(order.orderTotal),
            Tax: Number(order.orderTotal) * 0.25,
            TotalWithTax: Number(order.orderTotal) * 1.25,
          });
        }
        orderRows.push(i);
        i++;
        ws2Data.push({
          "Order Number": "",
          Company: `Order Number ${order.orderNumber}`,
          Date: "",
          Employee: "",
          Item: "",
          Price: "",
          Quantity: Number(order.orderQuantity),
          Total: Number(order.orderTotal),
        });
        order.orderItems.forEach((orderItem) => {
          i++;
          ws2Data.push({
            "Order Number": "",
            Company: order.companyName,
            Date: moment(order.orderDate).format("DD-MM-yyyy"),
            Employee: order.employee,
            Item: orderItem.itemName,
            Price: Number(orderItem.itemPrice),
            Quantity: Number(orderItem.itemQuantity),
            Total: Number(orderItem.itemTotal),
          });
        });
      });

      var workbook = new Workbook();
      var worksheet1 = workbook.addWorksheet("Canteen Report");
      var worksheet2 = workbook.addWorksheet("Canteen Orders");

      worksheet1.columns = [
        { header: "Company", key: "Company", width: 15 },
        { header: "Amount of orders", key: "OrderAmount", width: 15 },
        { header: "Total excl. tax", key: "Total", width: 15 },
        { header: "Total tax (25%)", key: "Tax", width: 15 },
        { header: "Total incl. tax", key: "TotalWithTax", width: 15 },
      ];

      worksheet2.columns = [
        { header: "Company", key: "Company", width: 15 },
        { header: "Order Number", key: "Order Number", width: 15 },
        { header: "Date", key: "Date", width: 10 },
        { header: "Employee", key: "Employee", width: 20 },
        { header: "Item", key: "Item", width: 10 },
        { header: "Price", key: "Price", width: 10 },
        { header: "Quantity", key: "Quantity", width: 10 },
        { header: "Total", key: "Total", width: 10 },
      ];

      worksheet1.addRows(ws1Data);
      worksheet1.getRow(1).border = { bottom: { style: "thin" } };

      worksheet2.addRows(ws2Data);
      worksheet2.getRow(1).border = { bottom: { style: "thin" } };

      orderRows.forEach((rowNr) => {
        worksheet2.getRow(rowNr).font = { bold: true };
        ["A", "B", "C", "D", "E", "F", "G", "H"].forEach((col) => {
          let cell = worksheet2.getCell(`${col}${rowNr}`);
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF46BDC6" },
          };
        });
      });
      workbook.xlsx.writeBuffer().then((d) => {
        const file = new Blob([d], {
          type: fileType,
        });
        saveAs(file, fileName + fileExtension);
      });

      return false;
    },
    download: user.role === "CanteenManager" ? false : true,
    filterType: "checkbox",
    selectableRows: "none",
    filter: false,
    print: false,
    sortOrder: {
      name: "orderNumber",
      direction: "asc",
    },
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const orderId = rowData[0];
      const order = orders[orderId];
      return (
        <>
          <tr>
            <td colSpan={4}>
              <TableContainer
                component={Paper}
                style={{
                  display: "flex",
                  width: "100%",
                  background: "#eeeeee",
                  marginBottom: "4px",
                }}
              >
                <Table style={{ minWidth: "650" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Employee</TableCell>
                      <TableCell>Item</TableCell>
                      <TableCell align="right">Price</TableCell>
                      <TableCell align="right">Quantity</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.orderItems.map((orderItem, index) => (
                      <TableRow key={`order-${orderId}-item-${index}`}>
                        <TableCell component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell>{order.companyName}</TableCell>
                        <TableCell>{moment(order.orderDate).format("DD/MM/yyyy")}</TableCell>
                        <TableCell>{order.employee}</TableCell>
                        <TableCell>{orderItem.itemName}</TableCell>
                        <TableCell align="right">{orderItem.itemPrice} kr.</TableCell>
                        <TableCell align="right">{orderItem.itemQuantity}</TableCell>
                        <TableCell align="right">{orderItem.itemTotal} kr.</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </>
      );
    },
  };

  return (
    <>
      <PageTitle title={"Canteen Reports"} />
      <CanteenReportsFilter
        canteens={canteens}
        companies={companies}
        query={query}
        setQuery={setQuery}
        handleRequest={handleQueryRequest}
      />
      {requesting ? <Loader /> : <MUIDataTable data={data} columns={columns} options={options} />}
    </>
  );
};

export default ManageCanteensPage;
