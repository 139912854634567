import React from "react";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { updateUser, unsetUser } from "../redux/actions/user";
import * as yup from "yup";

import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import loginBgImage from "../assets/login-bg-image.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  title: {
    fontSize: "3rem",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400",
    lineHeight: "1.167",
    letterSpacing: "0em",
  },
  image: {
    backgroundImage: `url(${loginBgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const validationSchema = yup.object({
  newPassword: yup.string().min(6, "Password must be at least 6 characters long "),
  confirmPassword: yup.string().oneOf([yup.ref("newPassword")], "Passwords must match"),
});

const ResetPasswordPage = ({ props }) => {
  const history = useHistory();
  const requesting = useSelector((state) => state.request.requesting);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const submitHandler = async (values) => {
    dispatch(updateUser(user.id, values));
    dispatch(unsetUser());
    history.push("/");
  };

  const classes = useStyles();
  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <>
            <Typography component="h1" className={classes.title}>
              Update Password
            </Typography>
            <Typography component="p" variant="body2">
              For security reasons, please enter a new password.
            </Typography>
            <Formik
              initialValues={{
                newPassword: "",
                confirmPassword: "",
              }}
              validateOnChange={true}
              validationSchema={validationSchema}
              onSubmit={submitHandler}
            >
              {({ submitForm, isSubmitting, status }) => (
                <Form className={classes.form}>
                  <Field
                    component={TextField}
                    margin="normal"
                    fullWidth
                    label="New Password"
                    name="newPassword"
                    type="password"
                    variant="outlined"
                  />
                  <Field
                    component={TextField}
                    fullWidth
                    margin="normal"
                    label="Confirm password"
                    name="confirmPassword"
                    type="password"
                    variant="outlined"
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={requesting}
                  >
                    Submit
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        </div>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordPage;
