import { takeLatest, call, put } from "redux-saga/effects";
import { sendNotification } from "../../utils/sendNotification";

import {
  ALL_RECEPTIONS_REQUEST,
  ALL_RECEPTIONS_REQUEST_SUCCESS,
  ALL_RECEPTIONS_REQUEST_FAILURE,
  ADD_RECEPTION,
  ADD_RECEPTION_SUCCESS,
  UPDATE_RECEPTION,
  UPDATE_RECEPTION_SUCCESS,
  DELETE_RECEPTION,
  DELETE_RECEPTION_SUCCESS,
} from "../constants/reception";

import {
  REQUEST_STARTED,
  REQUEST_FAIL,
  REQUEST_SUCCESS,
  REQUEST_FINISHED,
} from "../constants/request";

import {
  getAllReceptions,
  addReception,
  updateReception,
  deleteReception,
} from "../../services/reception.service";

export function* doRequestAllReceptions(action) {
  try {
    yield put({ type: REQUEST_STARTED });

    const payload = yield call(getAllReceptions);
    yield put({ type: ALL_RECEPTIONS_REQUEST_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
    yield put({ type: ALL_RECEPTIONS_REQUEST_FAILURE });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchRequestAllReceptions() {
  yield takeLatest(ALL_RECEPTIONS_REQUEST, doRequestAllReceptions);
}

export function* doAddReception(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const reception = yield call(addReception, payload.reception);
    yield put({ type: ADD_RECEPTION_SUCCESS, payload: { reception } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Reception successfully created", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchAddReception() {
  yield takeLatest(ADD_RECEPTION, doAddReception);
}

export function* doUpdateReception(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const reception = yield call(updateReception, payload.id, payload.reception);
    yield put({ type: UPDATE_RECEPTION_SUCCESS, payload: { reception } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Reception successfully updated", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchUpdateReception() {
  yield takeLatest(UPDATE_RECEPTION, doUpdateReception);
}

export function* doDeleteReception(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(deleteReception, payload.id);
    yield put({ type: DELETE_RECEPTION_SUCCESS, payload: { id: payload.id } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Reception successfully deleted", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchDeleteReception() {
  yield takeLatest(DELETE_RECEPTION, doDeleteReception);
}
