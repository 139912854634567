import React from "react";
import PropTypes from "prop-types";

import {Field, Formik} from "formik";
import {CheckboxWithLabel, Select, TextField} from "formik-material-ui";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import InputLabel from "@material-ui/core/InputLabel";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import {FormLabel} from "@material-ui/core";

const ProfileDetails = ({
  user,
  locations,
  validationSchema,
  profileSubmitHandler,
  requesting,
}) => {
  return (
    <Formik
      initialValues={{
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          mobile: user.mobile || "",
          office: user.office || "",
          primaryLocationId: user.primaryLocationId || "",
          password: "",
          newPassword: "",
          confirmPassword: "",
          emailNotificationEnabled: user.notificationSettings?.email ?? true,
          smsNotificationEnabled: user.notificationSettings?.sms ?? true,
      }}
      enableReinitialize
      validateOnChange={true}
      validationSchema={validationSchema}
      onSubmit={profileSubmitHandler}
      validate={(values) => {
          const errors = {};
          if (values.newPassword && !values.confirmPassword) {
              errors.confirmPassword = "Confirm your new password";
          }
          return errors;
      }}
    >
      {({ submitForm, isSubmitting, status, initialValues, errors }) => (
        <>
          <Card>
            <CardHeader subheader="The information can be edited" title="Profile" />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <Field
                    component={TextField}
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="First name"
                    name="firstName"
                    autoComplete="firstName"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    component={TextField}
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Last name"
                    name="lastName"
                    autoComplete="lastName"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    component={TextField}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    component={TextField}
                    variant="outlined"
                    required
                    fullWidth
                    id="mobile"
                    label="Mobile number"
                    name="mobile"
                    autoComplete="mobi  le"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Field
                    component={TextField}
                    variant="outlined"
                    required
                    fullWidth
                    id="office"
                    label="Employee Room Number"
                    InputProps={{
                      placeholder: "Room 201",
                    }}
                    name="office"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    error={!!errors["primaryLocationId"]}
                  >
                    <InputLabel id="primaryLocationLabel" htmlFor="primaryLocationId">
                      Primary Location
                    </InputLabel>
                    <Field
                        fullWidth
                        name="primaryLocationId"
                        type="text"
                        component={Select}
                        variant="outlined"
                        label="Primary Location"
                        labelId="primaryLocationLabel"
                        inputProps={{
                            name: "primaryLocationId",
                            id: "primaryLocationId",
                            label: "Primary Location",
                            placeholder: "Primary Location",
                        }}
                    >
                        {locations.map((val) => (
                            <MenuItem key={val.id + "-location"} value={val.id}>
                                {val.name}
                            </MenuItem>
                        ))}
                    </Field>
                      <FormHelperText>
                          {!!errors["primaryLocationId"] ? errors["primaryLocationId"] : ""}
                      </FormHelperText>
                  </FormControl>
                </Grid>
                  <Grid item xs={12}>
                      <FormLabel>Notification Settings</FormLabel>
                  </Grid>
                  <Grid item xs={6}>
                      <Field
                          component={CheckboxWithLabel}
                          color="primary"
                          Label={{label: 'Email notifications'}}
                          label="Email notifications"
                          name="emailNotificationEnabled"
                          type="checkbox"
                      />
                  </Grid>
                  <Grid item xs={6}>
                      <Field
                          component={CheckboxWithLabel}
                          color="primary"
                          Label={{label: 'SMS notifications'}}
                          name="smsNotificationEnabled"
                          type="checkbox"
                      />
                  </Grid>
                  <Grid item xs={12}>
                      <FormLabel>Change password</FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                      <Field
                          component={TextField}
                          fullWidth
                          label="Old Password"
                          name="password"
                          type="password"
                          variant="outlined"
                      />
                  </Grid>
                  <Grid item xs={12}>
                      <Field
                          component={TextField}
                          fullWidth
                          label="New Password"
                          name="newPassword"
                          type="password"
                          variant="outlined"
                      />
                  </Grid>
                  <Grid item xs={12}>
                      <Field
                          component={TextField}
                          fullWidth
                          label="Confirm password"
                          name="confirmPassword"
                          type="password"
                    variant="outlined"
                  />
                </Grid>

              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={submitForm}
                disabled={requesting}
              >
                Save details
              </Button>
            </Box>
          </Card>
        </>
      )}
    </Formik>
  );
};

ProfileDetails.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    image: PropTypes.string,
    role: PropTypes.string,
    created: PropTypes.string,
    updated: PropTypes.string,
    company: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      image: PropTypes.string,
      created: PropTypes.string,
      updated: PropTypes.string,
      OwnerId: PropTypes.number,
    }),
  }),
};
export default ProfileDetails;
