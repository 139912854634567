import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  addRoom,
  setRoomActive,
  setRoomInactive,
  deleteRoom,
  setActiveMultiple,
  setInactiveMultiple,
  deleteMultiple,
  updateRoom,
} from "../redux/actions/rooms";
import { ALL_ROOMS_REQUEST } from "../redux/constants/rooms";
import { ALL_LOCATIONS_REQUEST } from "../redux/constants/locations";
import { ALL_FEATURES_REQUEST } from "../redux/constants/features";

import { addRoomFormOptions, editRoomFormOptions } from "../services/formOptions";

import PageTitle from "../components/PageTitle";
import SubmitModal from "../containers/SubmitModal";
import CustomToolbar from "../components/CustomToolbar";
import Loader from "../components/Loader";
import RulesDialog from "../components/RulesDialog";
import EnlargedImageDialog from "../components/EnlargedImageDialog";
import ClickToEnlarge from "../components/ClickToEnlarge";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Switch from "@material-ui/core/Switch";

import { makeStyles } from "@material-ui/styles";

import ListIcon from "@material-ui/icons/List";

import MUIDataTable from "mui-datatables";
import TableTheme from "../utils/TableTheme";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chips: { margin: theme.spacing(0.5) },
  center: { textAlign: "center" },
}));

const initialDialogOptions = {
  title: "",
  descriptionText: "",
  formOptions: {
    initialValues: {},
    fields: [],
    validationSchema: null,
  },
  open: false,
  submitHandler: () => {},
  handleClose: () => {},
};

const ManageMeetingRoomsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rulesDialogOpen, setRulesDialogOpen] = useState(false);
  const [currentOpenRules, setCurrentOpenRules] = useState("");
  const data = useSelector((state) => state.rooms.rooms).map((room) => {
    try {
      return {
        ...room,
        location: room.location.id,
        locationName: room.location.name,
        features: room.features.map((feature) => feature.id),
        featuresNames: room.features.map((feature) => feature.name),
      };
    } catch (error) {
      return {
        ...room,
        location: 0,
        locationName: "undefined",
        features: room.features.map((feature) => feature.id),
        featuresNames: room.features.map((feature) => feature.name),
      };
    }
  });

  const [currentDialogOptions, setCurrentDialogOptions] = React.useState(initialDialogOptions);

  const handleOpenDialog = ({ title, descriptionText, formOptions, submitHandler }) => {
    setCurrentDialogOptions({
      title: title,
      descriptionText: descriptionText,
      formOptions: formOptions,
      open: true,
      submitHandler: submitHandler,
      handleClose: handleCloseDialog,
    });
  };

  const handleCloseDialog = () => {
    setCurrentDialogOptions(initialDialogOptions);
  };

  const [enlargedImageDialogOpen, setEnlargedImageDialogOpen] = React.useState(false);
  const [currentEnlargedImage, setCurrentEnlargedImage] = React.useState("");

  const handleClickEnlargeImage = (imageLink) => {
    setEnlargedImageDialogOpen(true);
    setCurrentEnlargedImage(imageLink);
  };
  const handleCloseEnlargedImage = () => {
    setEnlargedImageDialogOpen(false);
    setCurrentEnlargedImage("");
  };
  const locations = useSelector((state) => state.locations.locations);
  const features = useSelector((state) => state.features.features);

  const locationsOptions = locations.map((location) => ({
    name: location.name,
    value: location.id,
  }));
  const featuresOptions = features.map((feature) => ({
    name: feature.name,
    value: feature.id,
  }));

  addRoomFormOptions.fields[
    addRoomFormOptions.fields.findIndex((field) => field.name === "location")
  ].options = locationsOptions;

  addRoomFormOptions.fields[
    addRoomFormOptions.fields.findIndex((field) => field.name === "features")
  ].options = featuresOptions;

  editRoomFormOptions.fields[
    editRoomFormOptions.fields.findIndex((field) => field.name === "location")
  ].options = locationsOptions;

  editRoomFormOptions.fields[
    editRoomFormOptions.fields.findIndex((field) => field.name === "features")
  ].options = featuresOptions;

  const requesting = useSelector((state) => state.request.requesting);

  const columns = [
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: "excluded",
        print: false,
        download: false,
      },
    },
    {
      name: "image",
      label: "Image",
      options: {
        sort: false,
        download: false,
        filter: false,
        customHeadRender: () => (
          <th key={0} style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }} />
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <ClickToEnlarge imageLink={value} handleClickEnlargeImage={handleClickEnlargeImage} />
            </>
          );
        },
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
      },
    },
    {
      name: "rules",
      label: "Rules",
      options: {
        filter: false,
        download: false,
        print: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Tooltip title="Click to view rules">
                <IconButton
                  onClick={() => {
                    handleClickOpenRules(value);
                  }}
                >
                  <ListIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
    {
      name: "seatsNumber",
      label: "Number of seats",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value}</div>
            </>
          );
        },
      },
    },
    {
      name: "dailyLimit",
      label: "Daily limit",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value} hours</div>
            </>
          );
        },
      },
    },
    {
      name: "weeklyLimit",
      label: "Weekly limit",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <div>{value} hours</div>
            </>
          );
        },
      },
    },
    {
      name: "location",
      label: "Location",
      options: {
        filter: false,
        display: "excluded",
        download: false,
        print: false,
      },
    },
    {
      name: "locationName",
      label: "Location",
    },
    {
      name: "features",
      label: "Features",
      options: {
        filter: false,
        display: "excluded",
        print: false,
        download: false,
      },
    },
    {
      name: "featuresNames",
      label: "Features",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value.map((item) => (
                <div key={`feature-${item}-room-${tableMeta.rowData[0]}`}>
                  <Chip size="small" className={classes.chips} label={item} />
                </div>
              ))}
            </>
          );
        },
      },
    },
    {
      name: "isActive",
      label: "Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <FormControlLabel
              label={value ? "Active" : "Inactive"}
              value={value ? "Active" : "Inactive"}
              control={
                <Switch color="primary" checked={value} value={value ? "Active" : "Inactive"} />
              }
              onChange={(event) => {
                const id = tableMeta.rowData[0];
                if (event.target.value === "Active") {
                  dispatch(setRoomInactive(id));
                } else {
                  dispatch(setRoomActive(id));
                }
              }}
            />
          );
        },
      },
    },
    {
      name: "id",
      label: "Edit",
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleOpenDialog({
                  title: "Update Meeting Room",
                  descriptionText: "",
                  formOptions: {
                    ...editRoomFormOptions,
                    initialValues: {
                      name: tableMeta.rowData[2],
                      rules: tableMeta.rowData[3],
                      seatsNumber: tableMeta.rowData[4],
                      dailyLimit: tableMeta.rowData[5],
                      weeklyLimit: tableMeta.rowData[6],
                      location: tableMeta.rowData[7],
                      features: tableMeta.rowData[9],
                    },
                  },
                  submitHandler: (data) => {
                    updateRoomSubmitHandler(value, data);
                  },
                });
              }}
            >
              Edit
            </Button>
          );
        },
      },
    },
    {
      name: "id",
      label: "Delete",
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                const confirmAction = window.confirm(
                  `Are you sure you want to delete room ${tableMeta.rowData[2]} ? This action can not be reversed.`
                );
                if (confirmAction) deleteRoomHandler(value);
              }}
            >
              Delete
            </Button>
          );
        },
      },
    },
  ];

  useEffect(() => {
    dispatch({ type: ALL_ROOMS_REQUEST });
    dispatch({ type: ALL_LOCATIONS_REQUEST });
    dispatch({ type: ALL_FEATURES_REQUEST });
  }, [dispatch]);

  if (requesting && !currentDialogOptions.open) {
    return <Loader />;
  }
  const handleMarkSelectedAsActive = (items) => {
    dispatch(setActiveMultiple(items));
  };
  const handleMarkSelectedAsInactive = (items) => {
    dispatch(setInactiveMultiple(items));
  };
  const handleDeleteSelected = (items) => {
    const confirmAction = window.confirm(
      `Are you sure you want to delete ${items.length} rooms? This action can not be reversed.`
    );
    if (confirmAction) dispatch(deleteMultiple(items));
  };
  const handleClickOpenRules = (rules) => {
    setRulesDialogOpen(true);
    setCurrentOpenRules(rules);
  };
  const handleCloseOpenRules = () => {
    setRulesDialogOpen(false);
    setCurrentOpenRules("");
  };

  const addRoomSubmitHandler = async (data) => {
    dispatch(addRoom(data));
  };
  const updateRoomSubmitHandler = async (id, data) => {
    dispatch(updateRoom(id, data));
  };

  const deleteRoomHandler = async (id) => {
    dispatch(deleteRoom(id));
  };

  const options = {
    filterType: "checkbox",
    print: false,
    sortOrder: {
      name: "name",
      direction: "asc",
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
      <CustomToolbar
        selectedRows={selectedRows}
        displayData={displayData}
        setSelectedRows={setSelectedRows}
        handleMarkSelectedAsActive={handleMarkSelectedAsActive}
        handleMarkSelectedAsInactive={handleMarkSelectedAsInactive}
        handleDeleteSelected={handleDeleteSelected}
      />
    ),
  };

  return (
    <>
      <PageTitle
        title={"Manage Rooms"}
        button={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleOpenDialog({
                title: "Add new room",
                descriptionText: "",
                formOptions: addRoomFormOptions,
                submitHandler: addRoomSubmitHandler,
              });
            }}
          >
            Add new room
          </Button>
        }
      />
      <SubmitModal {...currentDialogOptions} />
      <MuiThemeProvider theme={TableTheme}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </MuiThemeProvider>
      <EnlargedImageDialog
        open={enlargedImageDialogOpen}
        handleClose={handleCloseEnlargedImage}
        imageLink={currentEnlargedImage}
      />{" "}
      <RulesDialog
        open={rulesDialogOpen}
        handleClose={handleCloseOpenRules}
        rules={currentOpenRules}
      />
    </>
  );
};

export default ManageMeetingRoomsPage;
