import React from "react";
import PropTypes from "prop-types";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  pinCode: {
    display: "flex",
  },
  pinCodeNumber: {
    margin: theme.spacing(1),
    width: "3rem",
    height: "3rem",
    textAlign: "center",
    fontSize: "2rem",
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderRadius: theme.shape.borderRadius,
    borderWidth: "1px",
    borderStyle: "solid",
    "&:hover": {
      borderColor: theme.palette.primary.dark,
    },
  },
}));

const Pin = ({ pinCode, requesting, pinResetHandler }) => {
  const classes = useStyles();
  return (
    <>
      <Card>
        <CardContent>
          <Box alignItems="center" display="flex" flexDirection="column">
            <Typography color="textPrimary" gutterBottom variant="h4">
              {"Pin Code"}
            </Typography>
            <div className={classes.pinCode}>
              <div className={classes.pinCodeNumber}>{pinCode[0]}</div>
              <div className={classes.pinCodeNumber}>{pinCode[1]}</div>
              <div className={classes.pinCodeNumber}>{pinCode[2]}</div>
              <div className={classes.pinCodeNumber}>{pinCode[3]}</div>
            </div>
          </Box>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            component="label"
            disabled={requesting}
            onClick={() => {
              const confirmAction = window.confirm("Are you sure you want to reset the PIN Code?");
              if (confirmAction) pinResetHandler();
            }}
          >
            Reset PIN
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

Pin.propTypes = {
  pinCode: PropTypes.string,
};

export default Pin;
