import React from "react";

// components
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
  },
  buttonsContainer: {
    "& button": {
      marginLeft: theme.spacing(2),
    },
  },
  typo: {
    color: theme.palette.text.hint,
  },
}));

export default function PageTitle(props) {
  const classes = useStyles();
  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h1" size="sm">
        {props.title}
      </Typography>
      <div className={classes.buttonsContainer}>{props.button && props.button}</div>
    </div>
  );
}
