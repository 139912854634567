import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {TableCell} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    dialogContent: {
        padding: 0
    },
    tableHead: {
        backgroundColor: "#eeeeee"
    }
});

export default function CompanyApplicationsModal({open, handleClose, applications, acceptHandle, declineHandler}) {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth="lg"
        >
            <DialogTitle>Company Applications</DialogTitle>
            <DialogContent dividers={true} className={classes.dialogContent}>
                <Table className={classes.table}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell>Company</TableCell>
                            <TableCell>Office</TableCell>
                            <TableCell>Contact Name</TableCell>
                            <TableCell/>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {applications.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell>{row.office}</TableCell>
                                <TableCell>{row.firstName + " " + row.lastName}</TableCell>
                                <TableCell><Button color="primary" onClick={acceptHandle(row)}>Accept</Button></TableCell>
                                <TableCell><Button color="secondary" onClick={declineHandler(row)}>Decline</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
