import React, { useEffect } from "react";
import * as yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import { updateUser, resetPin } from "../redux/actions/user";
import { CLEAR_REQUEST_INFO } from "../redux/constants/request";
import { USER_REQUEST } from "../redux/constants/user";
import { REQUEST_MY_LOCATIONS_AND_ROOMS } from "../redux/constants/calendar";

import ProfileView from "../containers/ProfileView";
import Loader from "../components/Loader";

const validationSchema = yup.object({
  email: yup.string().email("Please enter an valid email").required("This field is required"),
  firstName: yup.string().required("This field is required"),
  lastName: yup.string().required("This field is required"),
  office: yup.string().required("This field is required"),
  mobile: yup
    .string()
    .matches(/^\d+$/, "Only numbers allowed")
    .trim()
    .length(8, "Please enter valid 8 digits phone number ")
    .required("This field is required"),
  password: yup.string().min(6, "Password must be at least 6 characters long "),
  newPassword: yup.string().min(6, "Password must be at least 6 characters long "),
  confirmPassword: yup.string().oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  primaryLocationId: yup.number("Invalid location"),
  emailNotificationEnabled: yup.boolean("Invalid value").required("This field is required"),
  smsNotificationEnabled: yup.boolean("Invalid value").required("This field is required"),
});

const ProfilePage = () => {
  const user = useSelector((state) => state.user);
  const locations = useSelector((state) => state.calendar.locations);
  const error = useSelector((state) => state.request.error.message);
  const confirm = useSelector((state) => state.request.confirm);
  const requesting = useSelector((state) => state.request.requesting);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: USER_REQUEST });
    dispatch({ type: REQUEST_MY_LOCATIONS_AND_ROOMS });
  }, [dispatch]);

  const hideAlertHandler = () => {
    dispatch({ type: CLEAR_REQUEST_INFO });
  };
  const profileSubmitHandler = async (data) => {
    dispatch(updateUser(user.id, data));
  };
  const pinResetHandler = async () => {
    dispatch(resetPin(user.id));
  };
  if (requesting) {
    return <Loader />;
  }

  return (
    <div>
      <ProfileView
        user={user}
        locations={locations}
        validationSchema={validationSchema}
        profileSubmitHandler={profileSubmitHandler}
        pinResetHandler={pinResetHandler}
        hideAlertHandler={hideAlertHandler}
        error={error}
        confirm={confirm}
        requesting={requesting}
      />
    </div>
  );
};

export default ProfilePage;
