import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Routes from "./routes";
import ToastContainer from "./containers/ToastContainer";

import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./theme";

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline />
          <ToastContainer />
          <div>
            <Routes />
          </div>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
