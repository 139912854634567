import { takeLatest, call, put } from "redux-saga/effects";
import { sendNotification } from "../../utils/sendNotification";

import {
  ALL_FEATURES_REQUEST,
  ALL_FEATURES_REQUEST_SUCCESS,
  ALL_FEATURES_REQUEST_FAILURE,
  ADD_FEATURE,
  ADD_FEATURE_SUCCESS,
  UPDATE_FEATURE,
  UPDATE_FEATURE_SUCCESS,
  DELETE_FEATURE,
  DELETE_FEATURE_SUCCESS,
  MULTIPLE_FEATURES_ACTIVE,
  MULTIPLE_FEATURES_INACTIVE,
  MULTIPLE_FEATURES_DELETE,
  MULTIPLE_FEATURES_ACTIVE_SUCCESS,
  MULTIPLE_FEATURES_INACTIVE_SUCCESS,
  MULTIPLE_FEATURES_DELETE_SUCCESS,
} from "../constants/features";

import {
  REQUEST_STARTED,
  REQUEST_FAIL,
  REQUEST_SUCCESS,
  REQUEST_FINISHED,
} from "../constants/request";

import {
  getAllFeatures,
  addFeature,
  updateFeature,
  deleteFeature,
  deleteMultipleFeatures,
  setMultipleFeaturesActive,
  setMultipleFeaturesInactive,
} from "../../services/features.service";

export function* doRequestAllFeatures(action) {
  try {
    yield put({ type: REQUEST_STARTED });

    const payload = yield call(getAllFeatures);
    yield put({ type: ALL_FEATURES_REQUEST_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
    yield put({ type: ALL_FEATURES_REQUEST_FAILURE });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchRequestAllFeatures() {
  yield takeLatest(ALL_FEATURES_REQUEST, doRequestAllFeatures);
}

export function* doAddFeature(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const feature = yield call(addFeature, payload.feature);
    yield put({ type: ADD_FEATURE_SUCCESS, payload: { feature } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Feature successfully created", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchAddFeature() {
  yield takeLatest(ADD_FEATURE, doAddFeature);
}

export function* doUpdateFeature(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const feature = yield call(updateFeature, payload.id, payload.feature);
    yield put({ type: UPDATE_FEATURE_SUCCESS, payload: { feature } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Feature successfully updated", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchUpdateFeature() {
  yield takeLatest(UPDATE_FEATURE, doUpdateFeature);
}

export function* doDeleteFeature(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(deleteFeature, payload.id);
    yield put({ type: DELETE_FEATURE_SUCCESS, payload: { id: payload.id } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Feature successfully deleted", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchDeleteFeature() {
  yield takeLatest(DELETE_FEATURE, doDeleteFeature);
}

export function* doMultipleFeaturesActive(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(setMultipleFeaturesActive, payload.idList);
    yield put({ type: MULTIPLE_FEATURES_ACTIVE_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Features successfully updated", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchMultipleFeaturesActive() {
  yield takeLatest(MULTIPLE_FEATURES_ACTIVE, doMultipleFeaturesActive);
}

export function* doMultipleFeaturesInactive(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(setMultipleFeaturesInactive, payload.idList);
    yield put({ type: MULTIPLE_FEATURES_INACTIVE_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Features successfully updated", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchMultipleFeaturesInactive() {
  yield takeLatest(MULTIPLE_FEATURES_INACTIVE, doMultipleFeaturesInactive);
}
export function* doDeleteMultipleFeatures(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(deleteMultipleFeatures, payload.idList);
    yield put({ type: MULTIPLE_FEATURES_DELETE_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Features successfully deleted", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchDeleteMultipleFeatures() {
  yield takeLatest(MULTIPLE_FEATURES_DELETE, doDeleteMultipleFeatures);
}
