import React from "react";

import Profile from "../components/Profile";
import Pin from "../components/Pin";
import ProfileDetails from "../components/ProfileDetails";

import Grid from "@material-ui/core/Grid";

const ProfileView = ({
  user,
  locations,
  validationSchema,
  profileSubmitHandler,
  pinResetHandler,
  error,
  hideAlertHandler,
  confirm,
  requesting,
}) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={4} md={6} xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Profile user={user} profileSubmitHandler={profileSubmitHandler} />
            </Grid>
            {user.pinCode && (
              <Grid item xs={12}>
                <Pin
                  pinCode={user.pinCode}
                  requesting={requesting}
                  pinResetHandler={pinResetHandler}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item lg={8} md={6} xs={12}>
          <ProfileDetails
            user={user}
            locations={locations}
            validationSchema={validationSchema}
            profileSubmitHandler={profileSubmitHandler}
            hideAlertHandler={hideAlertHandler}
            error={error}
            confirm={confirm}
            requesting={requesting}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ProfileView;
