import React from "react";

import Typography from "@material-ui/core/Typography";

import InfoIcon from "@material-ui/icons/Info";
import ErrorIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SuccessIcon from "@material-ui/icons/SentimentVerySatisfied";

import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import blue from "@material-ui/core/colors/blue";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  notificationContainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: 45,
    minHeight: 45,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(0.5),
  },
  notificationIconContainer: {
    minWidth: 45,
    height: 45,
    borderRadius: 45,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 24,
  },
  messageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexGrow: 1,
    marginRight: "50px",
  },
  containedTypography: {
    color: "white",
  },
}));

export default function Notification({ message, type }) {
  var classes = useStyles();

  return (
    <div
      className={classes.notificationContainer}
      style={{
        backgroundColor: bgFromType(type),
      }}
    >
      <div className={classes.notificationIconContainer}>{iconFromType(type)}</div>
      <div className={classes.messageContainer}>
        <Typography className={classes.containedTypography} variant="h6">
          {message}
        </Typography>
      </div>
    </div>
  );
}

function iconFromType(type) {
  switch (type) {
    case "success":
      return <SuccessIcon />;
    case "info":
      return <InfoIcon />;
    case "error":
      return <ErrorIcon />;
    default:
      return <InfoIcon />;
  }
}

function bgFromType(type) {
  switch (type) {
    case "success":
      return green[200];
    case "info":
      return blue[200];
    case "error":
      return red[200];
    default:
      return blue[200];
  }
}
