export const LOGIN_REQUESTING = "LOGIN_REQUESTING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const SUBMIT_FORGOT_PASSWORD_REQUESTING = "SUBMIT_FORGOT_PASSWORD_REQUESTING";
export const SUBMIT_FORGOT_PASSWORD_SUCCESS = "SUBMIT_FORGOT_PASSWORD_SUCCESS";
export const SUBMIT_FORGOT_PASSWORD_FAIL = "SUBMIT_FORGOT_PASSWORD_FAIL";

export const VALIDATE_RESET_TOKEN_REQUESTING = "VALIDATE_RESET_TOKEN_REQUESTING";
export const VALIDATE_RESET_TOKEN_SUCCESS = "VALIDATE_RESET_TOKEN_SUCCESS";
export const VALIDATE_RESET_TOKEN_FAIL = "VALIDATE_RESET_TOKEN_FAIL";

export const RESET_PASSWORD_REQUESTING = "RESET_PASSWORD_REQUESTING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
