import React from "react";
import Notification from "../components/Notification";
import { toast } from "react-toastify";

export const sendNotification = (message, type) => {
  if (message === "Unauthorized") {
    return toast(
      <Notification message={"Your session has expired. Please log in again"} type={type} />,
      {
        type: type,
        progressClassName: "progress",
        className: "notification",
      }
    );
  }
  return toast(<Notification message={message} type={type} />, {
    type: type,
    progressClassName: "progress",
    className: "notification",
  });
};
