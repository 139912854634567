export const ALL_COMPANIES_REQUEST = "ALL_COMPANIES_REQUEST";
export const ALL_COMPANIES_REQUEST_SUCCESS = "ALL_COMPANIES_REQUEST_SUCCESS";
export const ALL_COMPANIES_REQUEST_FAILURE = "ALL_COMPANIES_REQUEST_FAILURE";

export const ADD_COMPANY = "ADD_COMPANY";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";

export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";

export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";

export const MULTIPLE_COMPANIES_DELETE = "MULTIPLE_COMPANIES_DELETE";
export const MULTIPLE_COMPANIES_DELETE_SUCCESS = "MULTIPLE_COMPANIES_DELETE_SUCCESS";

export const CREATE_COMPANY_APPLICATION_REQUEST = "CREATE_COMPANY_APPLICATION_REQUEST";
export const CREATE_COMPANY_APPLICATION_REQUEST_SUCCESS = "CREATE_COMPANY_APPLICATION_REQUEST_SUCCESS";
export const CREATE_COMPANY_APPLICATION_REQUEST_FAILURE = "CREATE_COMPANY_APPLICATION_REQUEST_FAILURE";

export const ALL_COMPANY_APPLICATIONS_REQUEST = "ALL_COMPANY_APPLICATIONS_REQUEST";
export const ALL_COMPANY_APPLICATIONS_REQUEST_SUCCESS = "ALL_COMPANY_APPLICATIONS_REQUEST_SUCCESS";
export const ALL_COMPANY_APPLICATIONS_REQUEST_FAILURE = "ALL_COMPANY_APPLICATIONS_REQUEST_FAILURE";

export const ACCEPT_COMPANY_APPLICATION_REQUEST = "ACCEPT_COMPANY_APPLICATION_REQUEST";
export const ACCEPT_COMPANY_APPLICATION_REQUEST_SUCCESS = "ACCEPT_COMPANY_APPLICATION_REQUEST_SUCCESS";
export const ACCEPT_COMPANY_APPLICATION_REQUEST_FAILURE = "ACCEPT_COMPANY_APPLICATION_REQUEST_FAILURE";

export const REJECT_COMPANY_APPLICATION_REQUEST = "REJECT_COMPANY_APPLICATION_REQUEST";
export const REJECT_COMPANY_APPLICATION_REQUEST_SUCCESS = "REJECT_COMPANY_APPLICATION_REQUEST_SUCCESS";
export const REJECT_COMPANY_APPLICATION_REQUEST_FAILURE = "REJECT_COMPANY_APPLICATION_REQUEST_FAILURE";
