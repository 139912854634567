import React from "react";

import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";

import ImageIcon from "@material-ui/icons/Image";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  avatar: {
    cursor: "pointer",
    "& :hover": {
      "& overlay": {
        opacity: 1,
      },
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
    width: "100%",
    opacity: 0,
    transition: ".3 ease",
    backgroundColor: "black",
  },
}));
const ClickToEnlarge = ({ imageLink, handleClickEnlargeImage }) => {
  const classes = useStyles();
  if (!imageLink) {
    return (
      <Avatar variant="square" alt="Room Image" src={imageLink}>
        <ImageIcon />
      </Avatar>
    );
  }
  return (
    <div>
      <Tooltip title="Click to zoom">
        <Avatar
          variant="square"
          src={imageLink}
          onClick={(e) => {
            handleClickEnlargeImage(imageLink);
          }}
          className={classes.avatar}
        >
          <ImageIcon />
        </Avatar>
      </Tooltip>
    </div>
  );
};

export default ClickToEnlarge;
