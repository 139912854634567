import {
  USER_SET,
  USER_UNSET,
  USER_REQUEST,
  USER_REQUEST_SUCCESS,
  USER_REQUEST_FAILURE,
} from "../constants/user";

const initialState = {
  isAuthenticated: false,
  id: null,
  token: null,
  pinCode: null,
  role: null,
  firstLogin: false,
  company: {
    id: null,
    name: null,
    image: null,
    OwnerId: null,
  },
  exp: null,
  firstName: null,
  lastName: null,
  email: null,
  image: null,
  mobile: null,
  office: null,
  requestSuccess: false,
  notificationSettings: {
    email: null,
    sms: null,
    push: null
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_SET:
      return {
        ...state,
        isAuthenticated: true,
        id: action.payload.id,
        token: action.payload.jwtToken,
        pinCode: action.payload.pinCode,
        role: action.payload.role,
        firstLogin: action.payload.firstLogin,
        exp: action.payload.exp,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
        image: action.payload.image,
        mobile: action.payload.mobile,
        primaryLocationId: action.payload.primaryLocationId,
        office: action.payload.office,
        company: {
          ...action.payload.company,
        },
        notificationSettings: action.payload.notificationSettings
      };
    case USER_UNSET:
      return { ...initialState };
    case USER_REQUEST:
      return {
        ...state,
      };
    case USER_REQUEST_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        pinCode: action.payload.pinCode,
        role: action.payload.role,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        firstLogin: action.payload.firstLogin,
        email: action.payload.email,
        image: action.payload.image,
        mobile: action.payload.mobile,
        primaryLocationId: action.payload.primaryLocationId,
        office: action.payload.office,
        company: {
          ...action.payload.company,
        },
        notificationSettings: action.payload.notificationSettings
      };
    case USER_REQUEST_FAILURE:
      return {
        ...initialState,
        requestSuccess: true,
      };

    default:
      return state;
  }
};
export default reducer;
