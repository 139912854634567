import React, { useEffect } from "react";
import * as yup from "yup";

import { useDispatch, useSelector } from "react-redux";

import { updateCompany } from "../redux/actions/company";

import { USER_REQUEST } from "../redux/constants/user";

import CompanyView from "../containers/CompanyView";
import Loader from "../components/Loader";

const validationSchema = yup.object({
  name: yup.string().required("This field is required"),
  office: yup.string().required("This field is required"),
});

const CompanyPage = () => {
  const company = useSelector((state) => state.user.company);
  const requesting = useSelector((state) => state.request.requesting);
  const user = useSelector((state) => state.user);
  const isOwner = user.role === "CompanyOwner" ? true : false;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: USER_REQUEST });
  }, [dispatch]);

  const companyUpdateHandler = async (data) => {
    dispatch(updateCompany(company.id, data));
  };

  if (requesting) {
    return <Loader />;
  }

  return (
    <div>
      <CompanyView
        company={company}
        validationSchema={validationSchema}
        companyUpdateHandler={companyUpdateHandler}
        requesting={requesting}
        isOwner={isOwner}
      />
    </div>
  );
};

export default CompanyPage;
