import {
  ADD_ROOM,
  MULTIPLE_ROOMS_ACTIVE,
  MULTIPLE_ROOMS_INACTIVE,
  MULTIPLE_ROOMS_DELETE,
  UPDATE_ROOM,
  DELETE_ROOM,
} from "../constants/rooms";

export const addRoom = (room) => {
  return {
    type: ADD_ROOM,
    payload: { room },
  };
};
export const updateRoom = (id, room) => {
  return {
    type: UPDATE_ROOM,
    payload: { id, room },
  };
};

export const setRoomActive = (id) => {
  return {
    type: UPDATE_ROOM,
    payload: { id, room: { isActive: true } },
  };
};

export const setRoomInactive = (id) => {
  return {
    type: UPDATE_ROOM,
    payload: { id, room: { isActive: false } },
  };
};

export const deleteRoom = (id) => {
  return {
    type: DELETE_ROOM,
    payload: { id },
  };
};
export const setActiveMultiple = (idList) => {
  return {
    type: MULTIPLE_ROOMS_ACTIVE,
    payload: { idList },
  };
};
export const setInactiveMultiple = (idList) => {
  return {
    type: MULTIPLE_ROOMS_INACTIVE,
    payload: { idList },
  };
};

export const deleteMultiple = (idList) => {
  return {
    type: MULTIPLE_ROOMS_DELETE,
    payload: { idList },
  };
};
