import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  overrides: {
    MUIDataTableBodyCell: {
      root: {
        textAlign: "center",
      },
    },
    MUIDataTableHeadCell: {
      root: {
        textAlign: "center",
      },
      toolButton: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  },
});

export default theme;
