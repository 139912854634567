import {sendNotification} from "../../utils/sendNotification";
import {call, put, takeLatest} from "redux-saga/effects";

import {
  ACCEPT_COMPANY_APPLICATION_REQUEST,
  ACCEPT_COMPANY_APPLICATION_REQUEST_FAILURE,
  ACCEPT_COMPANY_APPLICATION_REQUEST_SUCCESS,
  ADD_COMPANY,
  ADD_COMPANY_SUCCESS,
  ALL_COMPANIES_REQUEST,
  ALL_COMPANIES_REQUEST_FAILURE,
  ALL_COMPANIES_REQUEST_SUCCESS,
  ALL_COMPANY_APPLICATIONS_REQUEST,
  ALL_COMPANY_APPLICATIONS_REQUEST_FAILURE,
  ALL_COMPANY_APPLICATIONS_REQUEST_SUCCESS,
  CREATE_COMPANY_APPLICATION_REQUEST,
  CREATE_COMPANY_APPLICATION_REQUEST_SUCCESS,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  MULTIPLE_COMPANIES_DELETE,
  MULTIPLE_COMPANIES_DELETE_SUCCESS,
  REJECT_COMPANY_APPLICATION_REQUEST,
  REJECT_COMPANY_APPLICATION_REQUEST_FAILURE,
  REJECT_COMPANY_APPLICATION_REQUEST_SUCCESS,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
} from "../constants/company";
import {USER_REQUEST} from "../constants/user";

import {REQUEST_FAIL, REQUEST_FINISHED, REQUEST_STARTED, REQUEST_SUCCESS,} from "../constants/request";

import {
  addCompany,
  deleteCompany,
  deleteMultipleCompanies,
  getAllCompanies,
  updateCompany,
} from "../../services/company.service";
import {
  acceptCompany,
  createCompanyApplication,
  getAllCompanyApplications,
  rejectCompany
} from "../../services/company-applications.service";

export function* doRequestAllCompanies(action) {
  try {
    yield put({ type: REQUEST_STARTED });

    const payload = yield call(getAllCompanies);
    yield put({ type: ALL_COMPANIES_REQUEST_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
    yield put({ type: ALL_COMPANIES_REQUEST_FAILURE });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchRequestAllCompanies() {
  yield takeLatest(ALL_COMPANIES_REQUEST, doRequestAllCompanies);
}

export function* doAddCompany(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const company = yield call(addCompany, payload.company);
    yield put({ type: ADD_COMPANY_SUCCESS, payload: { company } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Company successfully created", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchAddCompany() {
  yield takeLatest(ADD_COMPANY, doAddCompany);
}

export function* doUpdateCompany(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const company = yield call(updateCompany, payload.id, payload.company);
    yield put({ type: UPDATE_COMPANY_SUCCESS, payload: { company } });
    yield put({ type: REQUEST_SUCCESS });
    yield put({ type: USER_REQUEST });
    sendNotification("Company successfully updated", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchUpdateCompany() {
  yield takeLatest(UPDATE_COMPANY, doUpdateCompany);
}

export function* doDeleteCompany(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(deleteCompany, payload.id);
    yield put({ type: DELETE_COMPANY_SUCCESS, payload: { id: payload.id } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Company successfully deleted", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchDeleteCompany() {
  yield takeLatest(DELETE_COMPANY, doDeleteCompany);
}

export function* doDeleteMultipleCompanies(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(deleteMultipleCompanies, payload.idList);
    yield put({ type: MULTIPLE_COMPANIES_DELETE_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Companies successfully deleted", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchDeleteMultipleCompanies() {
  yield takeLatest(MULTIPLE_COMPANIES_DELETE, doDeleteMultipleCompanies);
}

export function* doCreateCompanyApplication(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const company = yield call(createCompanyApplication, payload.companyApplication);
    yield put({ type: CREATE_COMPANY_APPLICATION_REQUEST_SUCCESS, payload: { company } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Application successfully submitted", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchCreateCompanyApplication() {
  yield takeLatest(CREATE_COMPANY_APPLICATION_REQUEST, doCreateCompanyApplication);
}


export function* doRequestAllCompanyApplications(action) {
  try {
    yield put({type: REQUEST_STARTED});

    const payload = yield call(getAllCompanyApplications);
    yield put({type: ALL_COMPANY_APPLICATIONS_REQUEST_SUCCESS, payload});
    yield put({type: REQUEST_SUCCESS});
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
    yield put({type: ALL_COMPANY_APPLICATIONS_REQUEST_FAILURE});
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchRequestAllCompanyApplications() {
  yield takeLatest(ALL_COMPANY_APPLICATIONS_REQUEST, doRequestAllCompanyApplications);
}

export function* doAcceptCompanyApplication(action) {
  try {
    const {payload} = action;
    yield put({type: REQUEST_STARTED});
    yield call(acceptCompany, payload.id);
    yield put({type: ACCEPT_COMPANY_APPLICATION_REQUEST_SUCCESS});
    yield put({type: REQUEST_SUCCESS});
    yield put({type: ALL_COMPANIES_REQUEST})
    yield put({type: ALL_COMPANY_APPLICATIONS_REQUEST})
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
    yield put({type: ACCEPT_COMPANY_APPLICATION_REQUEST_FAILURE});
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchAcceptCompanyApplication() {
  yield takeLatest(ACCEPT_COMPANY_APPLICATION_REQUEST, doAcceptCompanyApplication);
}

export function* doRejectCompanyApplication(action) {
  try {
    const {payload} = action;
    yield put({type: REQUEST_STARTED});
    yield call(rejectCompany, payload.id);
    yield put({type: REJECT_COMPANY_APPLICATION_REQUEST_SUCCESS});
    yield put({type: REQUEST_SUCCESS});
    yield put({type: ALL_COMPANY_APPLICATIONS_REQUEST})
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
    yield put({type: REJECT_COMPANY_APPLICATION_REQUEST_FAILURE});
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchRejectCompanyApplication() {
  yield takeLatest(REJECT_COMPANY_APPLICATION_REQUEST, doRejectCompanyApplication);
}
