import {combineReducers} from "redux";

import request from "./request";
import auth from "./auth";
import user from "./user";
import calendar from "./calendar";
import locations from "./locations";
import companies from "./companies";
import features from "./features";
import rooms from "./rooms";
import canteen from "./canteen";
import reception from "./reception";
import admin from "./user.admin";

const appReducer = combineReducers({
  auth,
  user,
  request,
  calendar,
  locations,
  companies,
  features,
  rooms,
  admin,
  canteen, reception
});

const rootReducer = (state, action) => {
  if (action.type === "USER_UNSET") {
    state = undefined;
  }
  return appReducer(state, action);
};
export default rootReducer;
