import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addCanteen, deleteCanteen, updateCanteen } from "../redux/actions/canteen";
import { addCanteenManager } from "../redux/actions/user.admin";

import { ALL_CANTEENS_REQUEST } from "../redux/constants/canteen";
import { ALL_LOCATIONS_REQUEST } from "../redux/constants/locations";

import {
  addCanteenFormOption,
  editCanteenFormOptions,
  addCanteenManagerFormOptions,
} from "../services/formOptions";

import PageTitle from "../components/PageTitle";
import SubmitModal from "../containers/SubmitModal";
import Loader from "../components/Loader";
import PasswordColumn from "../components/PasswordColumn";

import Button from "@material-ui/core/Button";

import MUIDataTable from "mui-datatables";
import TableTheme from "../utils/TableTheme";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

const initialDialogOptions = {
  title: "",
  descriptionText: "",
  formOptions: {
    initialValues: {},
    fields: [],
    validationSchema: null,
  },
  open: false,
  submitHandler: () => {},
  handleClose: () => {},
};

const ManageCanteensPage = () => {
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.user.role);
  const canteens = useSelector((state) => state.canteen.canteens);
  const locations = useSelector((state) => state.locations.locations);
  const requesting = useSelector((state) => state.request.requesting);

  const data = canteens.map((canteen) => {
    try {
      return {
        ...canteen,
        locationName: locations.find((location) => location.id === canteen.locationId).name || "",
        canteenManager: canteen.user
          ? `${canteen.user?.firstName} ${canteen.user?.lastName}`
          : "No manager selected",
      };
    } catch (error) {
      return {};
    }
  });

  const locationsOptions = locations.map((location) => ({
    name: location.name,
    value: location.id,
  }));

  const canteenOptions = canteens.map((canteen) => ({
    name: canteen.location.name,
    value: canteen.id,
  }));

  addCanteenManagerFormOptions.fields[
    addCanteenManagerFormOptions.fields.findIndex((field) => field.name === "canteenId")
  ].options = canteenOptions;

  addCanteenFormOption.fields[
    addCanteenFormOption.fields.findIndex((field) => field.name === "locationId")
  ].options = locationsOptions;

  editCanteenFormOptions.fields[
    editCanteenFormOptions.fields.findIndex((field) => field.name === "locationId")
  ].options = locationsOptions;

  const [currentDialogOptions, setCurrentDialogOptions] = React.useState(initialDialogOptions);

  const handleOpenDialog = ({ title, descriptionText, formOptions, submitHandler }) => {
    setCurrentDialogOptions({
      title: title,
      descriptionText: descriptionText,
      formOptions: formOptions,
      open: true,
      submitHandler: submitHandler,
      handleClose: handleCloseDialog,
    });
  };
  const handleCloseDialog = () => {
    setCurrentDialogOptions(initialDialogOptions);
  };
  const columns = [
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: "excluded",
        download: false,
      },
    },
    {
      name: "locationId",
      label: "Location",
      options: {
        filter: false,
        display: "excluded",
        download: false,
        print: false,
      },
    },
    {
      name: "locationName",
      label: "Location",
    },
    {
      name: "password",
      label: "Password",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <PasswordColumn password={value} />;
        },
      },
    },
    {
      name: "canteenManager",
      label: "Canteen Manager",
    },
    {
      name: "id",
      label: "Edit",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleOpenDialog({
                  title: "Edit Canteen",
                  descriptionText: "",
                  formOptions: {
                    ...editCanteenFormOptions,
                    initialValues: {
                      locationId: tableMeta.rowData[1],
                      password: tableMeta.rowData[3],
                    },
                  },
                  submitHandler: (data) => {
                    updateCanteenSubmitHandler(value, data);
                  },
                });
              }}
            >
              Edit
            </Button>
          );
        },
      },
    },
    {
      name: "id",
      label: "Delete",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                const confirmAction = window.confirm(
                  `Are you sure you want to delete canteen from location ${tableMeta.rowData[2]} ? This action can not be reversed.`
                );
                if (confirmAction) deleteCanteenHandler(value);
              }}
            >
              Delete
            </Button>
          );
        },
      },
    },
  ];

  useEffect(() => {
    dispatch({ type: ALL_CANTEENS_REQUEST });
    dispatch({ type: ALL_LOCATIONS_REQUEST });
  }, [dispatch]);

  if (requesting && !currentDialogOptions.open) {
    return <Loader />;
  }

  const addCanteenSubmitHandler = async (data) => {
    dispatch(addCanteen(data));
  };

  const addCanteenManagerSubmitHandler = async (data) => {
    dispatch(addCanteenManager(data));
  };

  const updateCanteenSubmitHandler = async (id, data) => {
    dispatch(updateCanteen(id, data));
  };

  const deleteCanteenHandler = async (id) => {
    dispatch(deleteCanteen(id));
  };

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    filter: false,
    print: false,
    sortOrder: {
      name: "locationName",
      direction: "asc",
    },
  };

  return (
    <>
      <PageTitle
        title={"Manage Canteens"}
        button={
          <>
            {["SuperAdmin", "Admin"].includes(userRole) && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenDialog({
                    title: "Add canteen manager",
                    descriptionText: "",
                    formOptions: addCanteenManagerFormOptions,
                    submitHandler: addCanteenManagerSubmitHandler,
                  });
                }}
              >
                Add canteen manager
              </Button>
            )}
            {userRole === "SuperAdmin" && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenDialog({
                    title: "Add new canteen",
                    descriptionText: "",
                    formOptions: addCanteenFormOption,
                    submitHandler: addCanteenSubmitHandler,
                  });
                }}
              >
                Add new canteen
              </Button>
            )}
          </>
        }
      />
      <SubmitModal {...currentDialogOptions} />
      <MuiThemeProvider theme={TableTheme}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </MuiThemeProvider>
    </>
  );
};

export default ManageCanteensPage;
