import { sendNotification } from "../../utils/sendNotification";

import { takeLatest, call, put, cancel } from "redux-saga/effects";
import {
  REQUEST_MY_LOCATIONS_AND_ROOMS,
  REQUEST_MY_LOCATIONS_AND_ROOMS_SUCCESS,
  REQUEST_MY_LOCATIONS_AND_ROOMS_FAIL,
  REQUEST_MY_BOOKINGS,
  REQUEST_MY_BOOKINGS_SUCCESS,
  REQUEST_MY_BOOKINGS_FAIL,
  ADD_BOOKING_REQUEST,
  ADD_BOOKING_REQUEST_SUCCESS,
  DELETE_BOOKING,
  DELETE_BOOKING_SUCCESS,
  UPDATE_BOOKING,
  UPDATE_BOOKING_SUCCESS,
  REQUEST_ROOMS_AVAILABILITY_SUCCESS,
  REQUEST_ROOMS_AVAILABILITY,
  REQUEST_MY_BOOKING_LIMITS_SUCCESS,
  REQUEST_MY_BOOKING_LIMITS,
} from "../constants/calendar";

import {
  REQUEST_STARTED,
  REQUEST_FAIL,
  REQUEST_SUCCESS,
  REQUEST_FINISHED,
} from "../constants/request";

import {
  getMyLocationsAndRooms,
  getCurrentBookingsForRoom,
  getCurrentBookingsForLocation,
  addBooking,
  getRoomsAvailability,
  deleteBooking,
  updateBooking,
  getUsedTimeForLocation,
} from "../../services/calendar.service";

export function* doRequestMyLocationsAndRooms(action) {
  try {
    yield put({ type: REQUEST_STARTED });

    const payload = yield call(getMyLocationsAndRooms);
    yield put({
      type: REQUEST_MY_LOCATIONS_AND_ROOMS_SUCCESS,
      payload: { locations: payload },
    });
    yield put({ type: REQUEST_SUCCESS });
  } catch (error) {
    sendNotification(error.data.message, "error");

    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
    yield put({ type: REQUEST_MY_LOCATIONS_AND_ROOMS_FAIL });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchRequestMyLocationsAndRooms() {
  yield takeLatest(REQUEST_MY_LOCATIONS_AND_ROOMS, doRequestMyLocationsAndRooms);
}

export function* doRequestMyBookings(action) {
  try {
    const { roomId, locationId } = action.payload;

    yield put({ type: REQUEST_STARTED });
    let payload;
    if (locationId) {
      payload = yield call(getCurrentBookingsForLocation, locationId);
    } else if (roomId) {
      payload = yield call(getCurrentBookingsForRoom, roomId);
    } else {
      yield cancel();
    }
    yield put({
      type: REQUEST_MY_BOOKINGS_SUCCESS,
      payload: { bookings: payload },
    });
    yield put({ type: REQUEST_SUCCESS });
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
    yield put({ type: REQUEST_MY_BOOKINGS_FAIL });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchRequestMyBookings() {
  yield takeLatest(REQUEST_MY_BOOKINGS, doRequestMyBookings);
}

export function* doAddBooking(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const booking = yield call(addBooking, payload.booking);
    yield put({ type: ADD_BOOKING_REQUEST_SUCCESS, payload: { booking } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Booking successfully created", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchAddBooking() {
  yield takeLatest(ADD_BOOKING_REQUEST, doAddBooking);
}

export function* doUpdateBooking(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const booking = yield call(updateBooking, payload.id, payload.booking);
    yield put({ type: UPDATE_BOOKING_SUCCESS, payload: { booking } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Booking successfully updated", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");

    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchUpdateBooking() {
  yield takeLatest(UPDATE_BOOKING, doUpdateBooking);
}

export function* doDeleteBooking(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(deleteBooking, payload.id);
    yield put({ type: DELETE_BOOKING_SUCCESS, payload: { id: payload.id } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Booking successfully deleted", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchDeleteBooking() {
  yield takeLatest(DELETE_BOOKING, doDeleteBooking);
}

export function* doRequestRoomAvailability(action) {
  try {
    const { booking } = action.payload;

    const payload = yield call(getRoomsAvailability, booking);
    yield put({
      type: REQUEST_ROOMS_AVAILABILITY_SUCCESS,
      payload: { availableRooms: payload },
    });
  } catch (error) {
  } finally {
  }
}

export function* watchRequestRoomAvailability() {
  yield takeLatest(REQUEST_ROOMS_AVAILABILITY, doRequestRoomAvailability);
}

export function* doRequestMyLimitsForLocation(action) {
  try {
    const { locationId } = action.payload;

    yield put({ type: REQUEST_STARTED });
    const payload = yield call(getUsedTimeForLocation, locationId);
    yield put({
      type: REQUEST_MY_BOOKING_LIMITS_SUCCESS,
      payload: { currentLimits: payload },
    });
    yield put({ type: REQUEST_SUCCESS });
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchRequestMyLimitsForLocation() {
  yield takeLatest(REQUEST_MY_BOOKING_LIMITS, doRequestMyLimitsForLocation);
}
