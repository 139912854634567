import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addCanteenItem, deleteCanteenItem, updateCanteenItem } from "../redux/actions/canteen";

import { ALL_CANTEEN_ITEMS_REQUEST } from "../redux/constants/canteen";

import { addCanteenItemFormOptions, editCanteenItemFormOptions } from "../services/formOptions";

import PageTitle from "../components/PageTitle";
import SubmitModal from "../containers/SubmitModal";
import Loader from "../components/Loader";

import Button from "@material-ui/core/Button";

import MUIDataTable from "mui-datatables";
import TableTheme from "../utils/TableTheme";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

const initialDialogOptions = {
  title: "",
  descriptionText: "",
  formOptions: {
    initialValues: {},
    fields: [],
    validationSchema: null,
  },
  open: false,
  submitHandler: () => {},
  handleClose: () => {},
};

const ManageCanteenItems = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.canteen.canteenItems);
  const requesting = useSelector((state) => state.request.requesting);

  const [currentDialogOptions, setCurrentDialogOptions] = React.useState(initialDialogOptions);

  const handleOpenDialog = ({ title, descriptionText, formOptions, submitHandler }) => {
    setCurrentDialogOptions({
      title: title,
      descriptionText: descriptionText,
      formOptions: formOptions,
      open: true,
      submitHandler: submitHandler,
      handleClose: handleCloseDialog,
    });
  };
  const handleCloseDialog = () => {
    setCurrentDialogOptions(initialDialogOptions);
  };
  const columns = [
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: "excluded",
        download: false,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: false,
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        filter: false,
      },
    },
    {
      name: "id",
      label: "Edit",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleOpenDialog({
                  title: "Edit Canteen Item",
                  descriptionText: "",
                  formOptions: {
                    ...editCanteenItemFormOptions,
                    initialValues: {
                      name: tableMeta.rowData[1],
                      price: tableMeta.rowData[2],
                    },
                  },
                  submitHandler: (data) => {
                    updateCanteenItemSubmitHandler(value, data);
                  },
                });
              }}
            >
              Edit
            </Button>
          );
        },
      },
    },
    {
      name: "id",
      label: "Delete",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                const confirmAction = window.confirm(
                  `Are you sure you want to delete item ${tableMeta.rowData[1]} ? This action can not be reversed.`
                );
                if (confirmAction) deleteCanteenItemHandler(value);
              }}
            >
              Delete
            </Button>
          );
        },
      },
    },
  ];

  useEffect(() => {
    dispatch({ type: ALL_CANTEEN_ITEMS_REQUEST });
  }, [dispatch]);

  if (requesting && !currentDialogOptions.open) {
    return <Loader />;
  }

  const addCanteenItemSubmitHandler = async (data) => {
    dispatch(addCanteenItem(data));
  };
  const updateCanteenItemSubmitHandler = async (id, data) => {
    dispatch(updateCanteenItem(id, data));
  };

  const deleteCanteenItemHandler = async (id) => {
    dispatch(deleteCanteenItem(id));
  };

  const options = {
    filterType: "checkbox",
    print: false,
    selectableRows: "none",
    filter: false,
    sortOrder: {
      name: "name",
      direction: "asc",
    },
  };

  return (
    <>
      <PageTitle
        title={"Manage Canteen Items"}
        button={
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleOpenDialog({
                title: "Add new canteen item",
                descriptionText: "",
                formOptions: addCanteenItemFormOptions,
                submitHandler: addCanteenItemSubmitHandler,
              });
            }}
          >
            Add new canteen item
          </Button>
        }
      />
      <SubmitModal {...currentDialogOptions} />
      <MuiThemeProvider theme={TableTheme}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </MuiThemeProvider>
    </>
  );
};

export default ManageCanteenItems;
