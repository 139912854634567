import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CloseIcon from "@material-ui/icons/Close";

import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  toastsContainer: {
    width: 400,
    marginTop: theme.spacing(1),
    right: 0,
    background: "transparent",
  },
  notificationCloseButton: {
    position: "absolute",
    right: theme.spacing(2),
  },
  progress: {
    visibility: "hidden",
  },
}));

const MyToastContainer = () => {
  const classes = useStyles();
  return (
    <div>
      <ToastContainer
        className={classes.toastsContainer}
        closeButton={<CloseButton className={classes.notificationCloseButton} />}
        progressClassName={classes.progress}
        autoClose={2000}
      />
    </div>
  );
};

export default MyToastContainer;

function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}
