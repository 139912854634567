import axios from "axios";

import config from "./baseRequestConfig";

const getMyLocationsAndRoomsUrl = "/api/location/mine/";
const addBookingUrl = "/api/booking/";
const updateBookingUrl = "/api/booking/";
const deleteBookingUrl = "/api/booking/";

const getRoomsAvailabilityUrl = "/api/booking/getAvailableRooms/";
const getCurrentBookingsUrl = "/api/booking/room/";
const getCurrentBookingsByLocationUrl = "/api/booking/location/";
const getUsedTimeUrl = "/api/booking/getUsedTime/";

export const getMyLocationsAndRooms = () => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .get(getMyLocationsAndRoomsUrl, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const getCurrentBookingsForRoom = (roomId) => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .get(getCurrentBookingsUrl + roomId, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const getCurrentBookingsForLocation = (locationId) => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .get(getCurrentBookingsByLocationUrl + locationId, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const addBooking = (booking) => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };

  return axios
    .post(addBookingUrl, booking, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const updateBooking = (id, booking) => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .put(updateBookingUrl + id, booking, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const deleteBooking = (id) => {
  const token = config();
  const cfg = {
    headers: {
      ...axios.defaults.headers,
      Authorization: token,
    },
  };
  return axios
    .delete(deleteBookingUrl + id, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const getRoomsAvailability = (booking) => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .post(getRoomsAvailabilityUrl, booking, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};
export const getUsedTimeForLocation = (locationId) => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .get(getUsedTimeUrl + locationId, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};
