import { takeLatest, call, put } from "redux-saga/effects";
import { sendNotification } from "../../utils/sendNotification";

import {
  ALL_CANTEENS_REQUEST,
  ALL_CANTEENS_REQUEST_SUCCESS,
  ALL_CANTEENS_REQUEST_FAILURE,
  ADD_CANTEEN,
  ADD_CANTEEN_SUCCESS,
  UPDATE_CANTEEN,
  UPDATE_CANTEEN_SUCCESS,
  DELETE_CANTEEN,
  DELETE_CANTEEN_SUCCESS,
  ALL_CANTEEN_ITEMS_REQUEST,
  ALL_CANTEEN_ITEMS_REQUEST_SUCCESS,
  ALL_CANTEEN_ITEMS_REQUEST_FAILURE,
  ADD_CANTEEN_ITEM,
  ADD_CANTEEN_ITEM_SUCCESS,
  UPDATE_CANTEEN_ITEM,
  UPDATE_CANTEEN_ITEM_SUCCESS,
  DELETE_CANTEEN_ITEM,
  DELETE_CANTEEN_ITEM_SUCCESS,
  QUERY_CANTEEN_REPORT_REQUEST_SUCCESS,
  QUERY_CANTEEN_REPORT_REQUEST,
  QUERY_COMPANY_CANTEEN_REPORT_REQUEST,
  QUERY_COMPANY_CANTEEN_REPORT_REQUEST_SUCCESS,
} from "../constants/canteen";

import {
  REQUEST_STARTED,
  REQUEST_FAIL,
  REQUEST_SUCCESS,
  REQUEST_FINISHED,
} from "../constants/request";

import {
  getAllCanteens,
  addCanteen,
  updateCanteen,
  deleteCanteen,
  getAllCanteenItems,
  addCanteenItem,
  updateCanteenItem,
  deleteCanteenItem,
  queryCanteenReport,
  queryCompanyCanteenReport,
} from "../../services/canteen.service";

export function* doRequestAllCanteens(action) {
  try {
    yield put({ type: REQUEST_STARTED });

    const payload = yield call(getAllCanteens);
    yield put({ type: ALL_CANTEENS_REQUEST_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
    yield put({ type: ALL_CANTEENS_REQUEST_FAILURE });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchRequestAllCanteens() {
  yield takeLatest(ALL_CANTEENS_REQUEST, doRequestAllCanteens);
}

export function* doAddCanteen(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const canteen = yield call(addCanteen, payload.canteen);
    yield put({ type: ADD_CANTEEN_SUCCESS, payload: { canteen } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Canteen successfully created", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchAddCanteen() {
  yield takeLatest(ADD_CANTEEN, doAddCanteen);
}

export function* doUpdateCanteen(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const canteen = yield call(updateCanteen, payload.id, payload.canteen);
    yield put({ type: UPDATE_CANTEEN_SUCCESS, payload: { canteen } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Canteen successfully updated", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchUpdateCanteen() {
  yield takeLatest(UPDATE_CANTEEN, doUpdateCanteen);
}

export function* doDeleteCanteen(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(deleteCanteen, payload.id);
    yield put({ type: DELETE_CANTEEN_SUCCESS, payload: { id: payload.id } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Canteen successfully deleted", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchDeleteCanteen() {
  yield takeLatest(DELETE_CANTEEN, doDeleteCanteen);
}

export function* doRequestAllCanteenItems(action) {
  try {
    yield put({ type: REQUEST_STARTED });

    const payload = yield call(getAllCanteenItems);
    yield put({ type: ALL_CANTEEN_ITEMS_REQUEST_SUCCESS, payload });
    yield put({ type: REQUEST_SUCCESS });
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
    yield put({ type: ALL_CANTEEN_ITEMS_REQUEST_FAILURE });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchRequestAllCanteenItems() {
  yield takeLatest(ALL_CANTEEN_ITEMS_REQUEST, doRequestAllCanteenItems);
}

export function* doAddCanteenItem(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const item = yield call(addCanteenItem, payload.item);
    yield put({ type: ADD_CANTEEN_ITEM_SUCCESS, payload: { item } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Canteen item successfully created", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchAddCanteenItem() {
  yield takeLatest(ADD_CANTEEN_ITEM, doAddCanteenItem);
}

export function* doUpdateCanteenItem(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const item = yield call(updateCanteenItem, payload.id, payload.item);
    yield put({ type: UPDATE_CANTEEN_ITEM_SUCCESS, payload: { item } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Canteen item successfully updated", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchUpdateCanteenItem() {
  yield takeLatest(UPDATE_CANTEEN_ITEM, doUpdateCanteenItem);
}

export function* doDeleteCanteenItem(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    yield call(deleteCanteenItem, payload.id);
    yield put({ type: DELETE_CANTEEN_ITEM_SUCCESS, payload: { id: payload.id } });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Canteen item successfully deleted", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchDeleteCanteenItem() {
  yield takeLatest(DELETE_CANTEEN_ITEM, doDeleteCanteenItem);
}

export function* doRequestCanteenReport(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const data = yield call(queryCanteenReport, payload.query);
    yield put({ type: QUERY_CANTEEN_REPORT_REQUEST_SUCCESS, payload: data });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Request successfully processed", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchRequestCanteenReport() {
  yield takeLatest(QUERY_CANTEEN_REPORT_REQUEST, doRequestCanteenReport);
}

export function* doRequestCompanyCanteenReport(action) {
  try {
    const { payload } = action;
    yield put({ type: REQUEST_STARTED });
    const data = yield call(queryCompanyCanteenReport, payload.query);
    yield put({ type: QUERY_COMPANY_CANTEEN_REPORT_REQUEST_SUCCESS, payload: data });
    yield put({ type: REQUEST_SUCCESS });
    sendNotification("Request successfully processed", "success");
  } catch (error) {
    sendNotification(error.data.message, "error");
    yield put({
      type: REQUEST_FAIL,
      payload: {
        message: error.data.message,
        statusCode: error.status,
      },
    });
  } finally {
    yield put({
      type: REQUEST_FINISHED,
    });
  }
}

export function* watchRequestCompanyCanteenReport() {
  yield takeLatest(QUERY_COMPANY_CANTEEN_REPORT_REQUEST, doRequestCompanyCanteenReport);
}
