import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { addReception, deleteReception, updateReception } from "../redux/actions/reception";

import { ALL_RECEPTIONS_REQUEST } from "../redux/constants/reception";
import { ALL_LOCATIONS_REQUEST } from "../redux/constants/locations";

import {
  addReceptionFormOptions,
  editReceptionFormOptions,
} from "../services/formOptions";

import PageTitle from "../components/PageTitle";
import SubmitModal from "../containers/SubmitModal";
import Loader from "../components/Loader";
import PasswordColumn from "../components/PasswordColumn";

import Button from "@material-ui/core/Button";

import MUIDataTable from "mui-datatables";
import TableTheme from "../utils/TableTheme";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

const initialDialogOptions = {
  title: "",
  descriptionText: "",
  formOptions: {
    initialValues: {},
    fields: [],
    validationSchema: null,
  },
  open: false,
  submitHandler: () => {},
  handleClose: () => {},
};

const ManageReceptionsPage = () => {
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.user.role);
  const receptions = useSelector((state) => state.reception.receptions);
  const locations = useSelector((state) => state.locations.locations);
  const requesting = useSelector((state) => state.request.requesting);

  const data = receptions.map((reception) => {
    try {
      return {
        ...reception,
        locationName: locations.find((location) => location.id === reception.locationId).name || "",
      };
    } catch (error) {
      return {};
    }
  });

  const locationsOptions = locations.map((location) => ({
    name: location.name,
    value: location.id,
  }));


  addReceptionFormOptions.fields[
    addReceptionFormOptions.fields.findIndex((field) => field.name === "locationId")
  ].options = locationsOptions;

  editReceptionFormOptions.fields[
    editReceptionFormOptions.fields.findIndex((field) => field.name === "locationId")
  ].options = locationsOptions;

  const [currentDialogOptions, setCurrentDialogOptions] = React.useState(initialDialogOptions);

  const handleOpenDialog = ({ title, descriptionText, formOptions, submitHandler }) => {
    setCurrentDialogOptions({
      title: title,
      descriptionText: descriptionText,
      formOptions: formOptions,
      open: true,
      submitHandler: submitHandler,
      handleClose: handleCloseDialog,
    });
  };
  const handleCloseDialog = () => {
    setCurrentDialogOptions(initialDialogOptions);
  };
  const columns = [
    {
      name: "id",
      options: {
        filter: false,
        sort: false,
        display: "excluded",
        download: false,
      },
    },
    {
      name: "locationId",
      label: "Location",
      options: {
        filter: false,
        display: "excluded",
        download: false,
        print: false,
      },
    },
    {
      name: "locationName",
      label: "Location",
    },
    {
      name: "password",
      label: "Password",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <PasswordColumn password={value} />;
        },
      },
    },
    {
      name: "name",
      label: "Reception Name",
    },
    {
      name: "wifiName",
      label: "Wifi Name",
    },
    {
      name: "wifiPass",
      label: "Wifi Password",
    },
    {
      name: "id",
      label: "Edit",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleOpenDialog({
                  title: "Edit Reception",
                  descriptionText: "",
                  formOptions: {
                    ...editReceptionFormOptions,
                    initialValues: {
                      locationId: tableMeta.rowData[1],
                      password: tableMeta.rowData[3],
                      name: tableMeta.rowData[4],
                      wifiName: tableMeta.rowData[5] || "",
                      wifiPass: tableMeta.rowData[6] || "",
                    },
                  },
                  submitHandler: (data) => {
                    updateReceptionSubmitHandler(value, data);
                  },
                });
              }}
            >
              Edit
            </Button>
          );
        },
      },
    },
    {
      name: "id",
      label: "Delete",
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button
              variant="outlined"
              color="secondary"
              onClick={(e) => {
                e.preventDefault();
                const confirmAction = window.confirm(
                  `Are you sure you want to delete reception from location ${tableMeta.rowData[2]} ? This action can not be reversed.`
                );
                if (confirmAction) deleteReceptionHandler(value);
              }}
            >
              Delete
            </Button>
          );
        },
      },
    },
  ];

  useEffect(() => {
    dispatch({ type: ALL_RECEPTIONS_REQUEST });
    dispatch({ type: ALL_LOCATIONS_REQUEST });
  }, [dispatch]);

  if (requesting && !currentDialogOptions.open) {
    return <Loader />;
  }

  const addReceptionSubmitHandler = async (data) => {
    dispatch(addReception(data));
  };


  const updateReceptionSubmitHandler = async (id, data) => {
    dispatch(updateReception(id, data));
  };

  const deleteReceptionHandler = async (id) => {
    dispatch(deleteReception(id));
  };

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    filter: false,
    print: false,
    sortOrder: {
      name: "locationName",
      direction: "asc",
    },
  };

  return (
    <>
      <PageTitle
        title={"Manage Receptions"}
        button={
          <>
            {userRole === "SuperAdmin" && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenDialog({
                    title: "Add new reception",
                    descriptionText: "",
                    formOptions: addReceptionFormOptions,
                    submitHandler: addReceptionSubmitHandler,
                  });
                }}
              >
                Add new reception
              </Button>
            )}
          </>
        }
      />
      <SubmitModal {...currentDialogOptions} />
      <MuiThemeProvider theme={TableTheme}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </MuiThemeProvider>
    </>
  );
};

export default ManageReceptionsPage;
