import axios from "axios";

import config from "./baseRequestConfig";

const getAllRoomsUrl = "/api/room/";
const addRoomUrl = "/api/room/";
const updateRoomUrl = "/api/room/";
const deleteRoomUrl = "/api/room/";

const setMultipleRoomsActiveUrl = "/api/room/set-active";
const setMultipleRoomsInactiveUrl = "/api/room/set-inactive";
const deleteMultipleRoomsUrl = "/api/room/delete";

export const getAllRooms = () => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .get(getAllRoomsUrl, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const addRoom = (room) => {
  const token = config();
  let cfg;
  try {
    room.get("image");
    cfg = {
      headers: {
        ...axios.defaults.headers,
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
  } catch (error) {
    cfg = {
      headers: { ...axios.defaults.headers, Authorization: token },
    };
  }
  return axios
    .post(addRoomUrl, room, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const updateRoom = (id, room) => {
  const token = config();
  let cfg;
  try {
    room.get("image");
    cfg = {
      headers: {
        ...axios.defaults.headers,
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
  } catch (error) {
    cfg = {
      headers: { ...axios.defaults.headers, Authorization: token },
    };
  }
  return axios
    .put(updateRoomUrl + id, room, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const deleteRoom = (id) => {
  const token = config();
  const cfg = {
    headers: {
      ...axios.defaults.headers,
      Authorization: token,
    },
  };
  return axios
    .delete(deleteRoomUrl + id, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const setMultipleRoomsActive = (idList) => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .put(setMultipleRoomsActiveUrl, { items: idList }, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const setMultipleRoomsInactive = (idList) => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .put(setMultipleRoomsInactiveUrl, { items: idList }, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};

export const deleteMultipleRooms = (idList) => {
  const token = config();
  const cfg = {
    headers: { ...axios.defaults.headers, Authorization: token },
  };
  return axios
    .post(deleteMultipleRoomsUrl, { items: idList }, cfg)
    .then((response) => response.data)
    .catch((error) => {
      throw error.response;
    });
};
