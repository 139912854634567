import { USER_SET, USER_UNSET, USER_UPDATE_PROFILE, RESET_PIN } from "../constants/user";

export const setUser = (payload) => {
  return {
    type: USER_SET,
    message: {
      text: "Successfully logged in!",
      severity: "success",
    },
    payload: { ...payload },
  };
};
export const resetPin = (id) => {
  return {
    type: RESET_PIN,
    payload: { id },
  };
};

export const unsetUser = () => {
  return {
    type: USER_UNSET,
  };
};

export const updateUser = (id, payload) => {
  return {
    type: USER_UPDATE_PROFILE,
    id,
    payload,
  };
};
