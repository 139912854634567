import React from "react";

import { useSelector } from "react-redux";

import DynamicForm from "../components/DynamicForm";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

const SubmitModal = ({ title, descriptionText, formOptions, open, submitHandler, handleClose }) => {
  const requestSuccess = useSelector((state) => state.request.confirm);

  React.useEffect(() => {
    if (requestSuccess) {
      handleClose();
    }
  }, [requestSuccess]);

  const confirmClose = () => {
    const confirmAction = window.confirm(`Are you sure you want to close this dialog window?`);
    if (confirmAction) handleClose();
  };

  return (
    <>
      <Dialog open={open} onClose={confirmClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DynamicForm
          handleClose={confirmClose}
          descriptionText={descriptionText}
          validationSchema={formOptions.validationSchema}
          fields={formOptions.fields}
          submitHandler={submitHandler}
          initialValues={formOptions.initialValues}
        />
      </Dialog>
    </>
  );
};

export default SubmitModal;
