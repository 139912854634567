export const getRoundTime = (date) => {
  // Getting minutes
  var mins = date.getMinutes();

  // Getting hours
  var hrs = date.getHours();

  var m = (parseInt((mins + 7.5) / 15) * 15) % 60;
  var h = mins > 52 ? (hrs === 23 ? 0 : ++hrs) : hrs;

  date.setMinutes(m);
  date.setHours(h);
  // if (date < new Date()) {
  //   date = new Date(date.getTime() + 15 * 60000);
  // }

  return date;
};

/**
 * The date range functions are used by the Scheduler component, when broadcasting range changes
 * anytime the user moves from one view to another, or traverses through a view's 'next' and
 * 'previous' states. They are not publically accessible, as they are not exported by the module.
 */
export const getDateFromRange = function (moment, range) {
  let startDate;
  if (Array.isArray(range)) {
    startDate = range[0];
  } else {
    const diff = moment(range.end).diff(moment(range.start), "days");
    if (diff > 25) {
      startDate = moment(range.start).add(15, "days").startOf("month");
    } else {
      startDate = range.start;
    }
  }

  return moment(startDate).startOf("day").toDate();
};

export const formValuesToSendValues = function (bookingTime, moment) {
  return moment(bookingTime).second(0).toDate();
};

export const mergeDateAndTime = function (date, time, moment) {
  return moment(date).hours(time.hours()).minutes(time.minutes()).seconds(0).toDate();
};
