import {
  ADD_COMPANY_SUCCESS,
  ALL_COMPANIES_REQUEST_SUCCESS,
  ALL_COMPANY_APPLICATIONS_REQUEST_SUCCESS,
  DELETE_COMPANY_SUCCESS,
  MULTIPLE_COMPANIES_DELETE_SUCCESS,
  UPDATE_COMPANY_SUCCESS,
} from "../constants/company";

const initialState = {
  companies: [],
  companyApplications: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_COMPANIES_REQUEST_SUCCESS:
      return {
        ...state,
        companies: action.payload,
      };
    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        companies: [...state.companies, action.payload.company],
      };
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: state.companies.map((item) => {
          return action.payload.company.id === item.id
              ? {...action.payload.company}
              : {...item};
        }),
      };
    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        companies: state.companies.filter((item) => action.payload.id !== item.id),
      };
    case MULTIPLE_COMPANIES_DELETE_SUCCESS:
      return {
        ...state,
        companies: state.companies.filter((item) => !action.payload.idList.includes(item.id)),
      };
    case ALL_COMPANY_APPLICATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        companyApplications: action.payload,
      };
    default:
      return state;
  }
};
export default reducer;
