import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import * as yup from "yup";
import { validatePasswordResetToken, resetPassword } from "../redux/actions/auth";

import { Formik, Field, Form } from "formik";
import { TextField } from "formik-material-ui";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import MuiLink from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import loginBgImage from "../assets/login-bg-image.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  title: {
    fontSize: "3rem",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: "400",
    lineHeight: "1.167",
    letterSpacing: "0em",
  },
  image: {
    backgroundImage: `url(${loginBgImage})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light" ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const validationSchema = yup.object({
  password: yup.string().min(6, "Password must be at least 6 characters long "),
  confirmPassword: yup.string().oneOf([yup.ref("password")], "Passwords must match"),
});

const ResetPasswordPage = ({ props }) => {
  const history = useHistory();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const error = useSelector((state) => state.auth.error);
  const requesting = useSelector((state) => state.auth.requesting);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(validatePasswordResetToken({ token }));
  }, [token, dispatch]);

  const submitHandler = async (values) => {
    dispatch(resetPassword({ token, ...values }, history));
  };

  const classes = useStyles();
  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          {error ? (
            <Alert severity="error">{error}</Alert>
          ) : (
            <>
              <Typography component="h1" className={classes.title}>
                Password Reset
              </Typography>
              <Typography component="p" variant="body2">
                Enter new password
              </Typography>
              <Formik
                initialValues={{
                  password: "",
                  confirmPassword: "",
                }}
                validateOnChange={true}
                validationSchema={validationSchema}
                onSubmit={submitHandler}
              >
                {({ submitForm, isSubmitting, status }) => (
                  <Form className={classes.form}>
                    <Field
                      component={TextField}
                      margin="normal"
                      fullWidth
                      label="New Password"
                      name="password"
                      type="password"
                      variant="outlined"
                    />
                    <Field
                      component={TextField}
                      fullWidth
                      margin="normal"
                      label="Confirm password"
                      name="confirmPassword"
                      type="password"
                      variant="outlined"
                    />

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={requesting}
                      onClick={submitForm}
                    >
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </>
          )}
          <Box mt={5}>
            <MuiLink variant="body2" component={Link} to="/">
              Return to log in
            </MuiLink>
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordPage;
