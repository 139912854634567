import React from "react";
import moment from "moment";
import { getRoundTime } from "../utils/timeUtils";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MuiMenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";

import { makeStyles, withStyles } from "@material-ui/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

moment.locale("en-GB", {
  week: {
    dow: 1,
    doy: 1,
  },
});

const initialBookingValues = {
  name: "",
  bookingDate: moment(getRoundTime(new Date())),
  bookingStartTime: moment(getRoundTime(new Date())).add(30, "minutes"),
  bookingEndTime: moment(getRoundTime(new Date())).add(90, "minutes"),
};

const MenuItem = withStyles({
  root: {
    justifyContent: "space-between",
    minWidth: "200px",
  },
})(MuiMenuItem);

const useStyles = makeStyles((theme) => ({
  padBottom: { paddingBottom: theme.spacing(2) },
  marginLeft: { marginLeft: theme.spacing(1) },
  marginRight: { marginRight: theme.spacing(1) },
  dropdown: { zIndex: "12" },
  dateTitle: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: " #3c4043",
    fontSize: "22px",
    fontWeight: 400,
    letterSpacing: "0",
    lineHeight: "28px",
    whiteSpace: "nowrap",
  },
  addButton: {
    margin: theme.spacing(1),
  },
}));

const viewToKey = {
  week: "W",
  work_week: "X",
  day: "D",
  month: "M",
  agenda: "A",
};

const CustomCalendarToolbar = ({
  view,
  views,
  label,
  localizer,
  onNavigate,
  onView,
  handleOpenBookingModal,
}) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const viewNamesGroup = (messages) => {
    if (views.length > 1) {
      return views.map((name) => (
        <MenuItem
          key={name}
          onClick={(event) => {
            onView(name);
            handleClose(event);
          }}
        >
          <div>{messages[name]}</div>
          <div>{viewToKey[name]}</div>
        </MenuItem>
      ));
    }
  };

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      className={classes.padBottom}
    >
      <Button
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        endIcon={<ArrowDropDownIcon />}
      >
        {localizer.messages[view]}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.dropdown}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {viewNamesGroup(localizer.messages)}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Button
        className={classes.marginLeft}
        variant="outlined"
        onClick={() => {
          onNavigate("TODAY");
        }}
      >
        {localizer.messages.today}
      </Button>
      <Tooltip title={localizer.messages.previous} aria-label="previous">
        <IconButton
          className={classes.marginLeft}
          aria-label="previous"
          onClick={() => {
            onNavigate("PREV");
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={localizer.messages.next} aria-label="next">
        <IconButton
          aria-label="previous"
          onClick={() => {
            onNavigate("NEXT");
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </Tooltip>

      <Box flexGrow="1" className={classes.dateTitle}>
        {label}
      </Box>

      <Fab
        className={classes.addButton}
        aria-label="add"
        color="primary"
        onClick={() => {
          handleOpenBookingModal(initialBookingValues);
        }}
      >
        <AddIcon />
      </Fab>
    </Box>
  );
};

export default CustomCalendarToolbar;
