export const ALL_FEATURES_REQUEST = "ALL_FEATURES_REQUEST";
export const ALL_FEATURES_REQUEST_SUCCESS = "ALL_FEATURES_REQUEST_SUCCESS";
export const ALL_FEATURES_REQUEST_FAILURE = "ALL_FEATURES_REQUEST_FAILURE";

export const ADD_FEATURE = "ADD_FEATURE";
export const ADD_FEATURE_SUCCESS = "ADD_FEATURE_SUCCESS";

export const UPDATE_FEATURE = "UPDATE_FEATURE";
export const UPDATE_FEATURE_SUCCESS = "UPDATE_FEATURE_SUCCESS";

export const DELETE_FEATURE = "DELETE_FEATURE";
export const DELETE_FEATURE_SUCCESS = "DELETE_FEATURE_SUCCESS";

export const MULTIPLE_FEATURES_ACTIVE = "MULTIPLE_FEATURES_ACTIVE";
export const MULTIPLE_FEATURES_ACTIVE_SUCCESS =
  "MULTIPLE_FEATURES_ACTIVE_SUCCESS";
export const MULTIPLE_FEATURES_INACTIVE = "MULTIPLE_FEATURES_INACTIVE";
export const MULTIPLE_FEATURES_INACTIVE_SUCCESS =
  "MULTIPLE_FEATURES_INACTIVE_SUCCESS";
export const MULTIPLE_FEATURES_DELETE = "MULTIPLE_FEATURES_DELETE";
export const MULTIPLE_FEATURES_DELETE_SUCCESS =
  "MULTIPLE_FEATURES_DELETE_SUCCESS";
