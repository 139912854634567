import {
  ALL_FEATURES_REQUEST_SUCCESS,
  ADD_FEATURE_SUCCESS,
  UPDATE_FEATURE_SUCCESS,
  DELETE_FEATURE_SUCCESS,
  MULTIPLE_FEATURES_ACTIVE_SUCCESS,
  MULTIPLE_FEATURES_INACTIVE_SUCCESS,
  MULTIPLE_FEATURES_DELETE_SUCCESS,
} from "../constants/features";

const initialState = {
  features: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ALL_FEATURES_REQUEST_SUCCESS:
      return {
        features: action.payload,
      };
    case ADD_FEATURE_SUCCESS:
      return {
        ...state,
        features: [...state.features, action.payload.feature],
      };
    case UPDATE_FEATURE_SUCCESS:
      return {
        features: state.features.map((item) => {
          return action.payload.feature.id === item.id
            ? { ...action.payload.feature }
            : { ...item };
        }),
      };
    case DELETE_FEATURE_SUCCESS:
      return {
        features: state.features.filter(
          (item) => action.payload.id !== item.id
        ),
      };
    case MULTIPLE_FEATURES_ACTIVE_SUCCESS:
      return {
        features: state.features.map((item) => {
          return action.payload.idList.includes(item.id)
            ? { ...item, isActive: true }
            : { ...item };
        }),
      };
    case MULTIPLE_FEATURES_INACTIVE_SUCCESS:
      return {
        features: state.features.map((item) => {
          return action.payload.idList.includes(item.id)
            ? { ...item, isActive: false }
            : { ...item };
        }),
      };
    case MULTIPLE_FEATURES_DELETE_SUCCESS:
      return {
        features: state.features.filter(
          (item) => !action.payload.idList.includes(item.id)
        ),
      };
    default:
      return state;
  }
};
export default reducer;
